import React from "react";
import "../../App.css";
import Navbar from "../Navbar";
import Pricelist from "../Pricelist";


function Angebot() {
  return (
    <>
      
      <Navbar />
      <Pricelist />
      
    </>
  );
}

export default Angebot;
