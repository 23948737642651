import React from "react";
import "../App.css";
import kontaktpic from "./../assets/neon_x_manzara.jpg";
import { Button } from "./Button";
import { useState } from "react";
import "./KontaktFormular.css";
import "./Footer.css";
import { ToastContainer, toast } from "react-toastify";
import { EMAIL_SUCCESS_MSG, EMAIL_ERR_MSG } from "./../util/constants";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function KontaktUs() {
  const [reservationDate, setreservationDate] = useState(new Date());

  async function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    if (!validateInput(formData)) {
      //TODO toast.error...
      return;
    }
    const body = {};
    formData.forEach((v, k) => {
      body[k] = v;
    });
    body["reservationDate"] = reservationDate;
    //TODO: encapsulate this logic in shared fun (repetitive code for all forms)
    try {
      const response = await fetch(
        "https://ohuxupah.myhostpoint.ch/server/mail.php",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        toast.success(EMAIL_SUCCESS_MSG);
      } else {
        toast.error(EMAIL_ERR_MSG);
      }
    } catch (error) {
      console.error(error);
      toast.error(EMAIL_ERR_MSG);
    }
  }

  //TODO: complete this validation fn
  function validateInput(data) {
    return true;
  }

  return (
    <>
      <div className="container-fluid mt-5">

        <form onSubmit={handleFormSubmit}>
          <div class="row">
            <div class="col-6 col-md-4">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                className="footer-input"
                name="name"
                type="text"
                placeholder="Ihr Name"
              />
            </div>
            <div class="col-6 col-md-4">
              <label for="emailAddress">Email</label>
              <input
                id="emailAddress"
                className="footer-input"
                name="emailAddress"
                type="email"
                placeholder="Ihr Email"
              />
            </div>
            <div class="col-6 col-md-4">
              <label for="phoneNumber">Tel. Nummer</label>
              <input
                id="phoneNumber"
                className="footer-input"
                name="phoneNumber"
                type="text"
                placeholder="Ihre Nummer"
              />
            </div>
            <div class="col-6 col-md-4">
              <label htmlFor="reservationDate">Datum</label>
              <br />
              <DatePicker
                id="reservationDate"
                name="reservationDate"
                selected={reservationDate}
                onChange={(date) => setreservationDate(date)}
              />
            </div>
            <div class="col-6 col-md-4">
              <label for="numberOfPeople">Anzahl Gäste</label>
              <input
                id="numberOfPeople"
                className="footer-input"
                name="numberOfPeople"
                type="number"
                placeholder="1"
              />
            </div>
            <div class="col-6 col-md-4">
              <label for="numberOfPeople">Art des Anlasses</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1" checked
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Standard Drink Service
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio" name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Drink & Food Service
                </label>
              </div>
            </div>
            <div class="col-6">
              <label for="messageBody">Nachricht</label>
              <br />
              <textarea
                id="messageBody"
                style={{ width: "100%" }}
                minLength={"500px"}
                className="footer-input"
                name="messageBody"
                placeholder="Ihre Nachricht"
              />
            </div>
            <div class="col-12">
              <button class="btn--outline btn--medium" type="submit">
                Senden
              </button>
            </div>
          </div>
        </form>
      </div>

    </>
  );
}

export default KontaktUs;
